import apiClient from '@/services/axios'

const getProvince = async () => {
    const res = await apiClient.get('/api/locations/provinsi.json')
    if (res) {
        return res.data
    } else {
        return null
    }
}

const getRegency = async (id) => {
    const res = await apiClient.get(`api/locations/kabupaten/${id}.json`)
    if (res) {
        return res.data
    } else {
        return null
    }
}

const getDistrict = async (id) => {
    const res = await apiClient.get(`api/locations/kecamatan/${id}.json`)
    if (res) {
        return res.data
    } else {
        return null
    }
}

const getVillage = async (id) => {
    const res = await apiClient.get(`api/locations/kelurahan/${id}.json`)
    if (res) {
        return res.data
    } else {
        return null
    }
}

export {
    getProvince,
    getRegency,
    getDistrict,
    getVillage,
}